import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { KiloPayments, Loader } from 'components';
import { AppState } from 'state/types';

interface PaymentsFormProps {
  onSuccess: (data: any) => Promise<void>;
}

const Container = styled.div`
  width: 100%;
  min-height: 10.66rem;
  max-width: 21.4375rem;
  flex-shrink: 0;
`;

const LoaderStyled = styled(Loader)`
  height: 10.66rem;
`;

const PaymentsForm: FC<PaymentsFormProps> = ({ onSuccess, ...props }) => {
  const { code, selected_plan } = useSelector((s: AppState) => s.user);
  return (
    <Container {...props}>
      <KiloPayments
        code={code}
        initialProduct={selected_plan}
        handleError={console.error}
        handleSuccess={onSuccess}
      />
    </Container>
  );
};

export default PaymentsForm;
